import React from 'react';

const UtilityCard = ({ utility }) => {
  const IconComponent = utility.icon; // Get the icon component

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 flex flex-col items-center">
      <div className="text-blue-500 mb-2">
        <IconComponent /> {/* Render the icon as a component */}
      </div>
      <h3 className="text-lg font-semibold mb-1">{utility.name}</h3>
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
        {utility.category}
      </p>
      <a href={utility.path}>
        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
          Use Now
        </button>
      </a>
    </div>
  );
};

export default UtilityCard;
