import React, { useState } from 'react';

const JsonFormatter = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [formattedJson, setFormattedJson] = useState('');
  const [error, setError] = useState('');

  const handleFormatJson = () => {
    try {
      const parsedJson = JSON.parse(jsonInput); // Parse the JSON input
      const beautifiedJson = JSON.stringify(parsedJson, null, 4); // Beautify JSON
      setFormattedJson(beautifiedJson);
      setError('');
    } catch (error) {
      setFormattedJson('');
      setError('Invalid JSON format');
    }
  };

  const handleClear = () => {
    setJsonInput('');
    setFormattedJson('');
    setError('');
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h1 className="text-2xl font-bold text-center">
        JSON Formatter and Validator
      </h1>
      <p className="text-center">
        Beautify, validate, and format JSON data for improved readability.
      </p>
      <div className="space-y-4">
        <textarea
          className="w-full p-2 border rounded-md"
          rows="10"
          placeholder="Paste your JSON here..."
          value={jsonInput}
          onChange={e => setJsonInput(e.target.value)}
        ></textarea>
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-between space-x-2">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleFormatJson}
          >
            Format JSON
          </button>
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
        {formattedJson && (
          <div>
            <h2 className="text-lg font-semibold">Formatted JSON:</h2>
            <textarea
              data-testid="result_textarea"
              className="w-full p-2 border rounded-md"
              rows="10"
              value={formattedJson}
              readOnly
            ></textarea>
          </div>
        )}
      </div>
    </div>
  );
};

export default JsonFormatter;
